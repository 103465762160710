
jQuery(document).ready(function($){

    //change image on click
    $("#audio").click(function(){
        $(".audio_btn").toggleClass("pause");
    });

    $(".main_nav a").on("click", function(){
        $(".main_nav").find(".active").removeClass("active");
        $(this).parent().addClass("active");
    });

    $('.lang_nav li a').on('click', function(){
        $('.lang_nav li a.active').removeClass('active');
        $(this).addClass('active');
    });

    //footer change on responsive width
    if ($(window).width() < 510) {
        $('.footer_section .footer_email').append($('.social_icons'));
    }

});

